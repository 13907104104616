import React from "react";

class Purchase_complete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <b>Your payment has been successful!</b>
      </div>
    );
  }
}

export default Purchase_complete;
