import React from "react";

const Powered_by_voucher_africa = () => {
  return (
    <div
      style={{
        textAlign: "center",
        width: "100%",
      }}
    >
      Powered by Voucher Africa
      <i class="ti-unlock"></i>
    </div>
  );
};

export default Powered_by_voucher_africa;
