import React from "react";

class Pay_on_delivery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <p>
          Anim aliqua excepteur occaecat ut. Reprehenderit cupidatat sunt magna
          laborum. Magna esse labore ut do esse culpa in anim proident. Minim et
          cupidatat velit esse dolore non ea veniam proident. Excepteur anim
          laborum laboris ipsum aute laboris tempor aute exercitation labore
          cupidatat.
        </p>

        <a href="#" class="btn full-width theme-bg text-white mt-1">
          Place order
        </a>
      </>
    );
  }
}

export default Pay_on_delivery;
